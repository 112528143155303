<template>
  <div
    class="active_center"
    v-loading="loading"
    element-loading-text="加载中..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="form_info">
      <el-form ref="form" :model="queryInfo" label-width="80px">
        <div class="form">
          <div class="comble_txt">
            <el-form-item label="活动状态:" prop="">
              <el-radio-group v-model="queryInfo.activityState" @change="search">
                <el-radio-button
                  :label="item.id"
                  v-for="(item, index) in InformationTypes"
                  :key="index"
                  >{{ item.value }}</el-radio-button
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item label="活动阶段:" prop="">
              <el-radio-group v-model="queryInfo.activityPhase" @change="search">
                <el-radio-button
                  :label="item.id"
                  v-for="(item, index) in activityPhase"
                  :key="index"
                  >{{ item.value }}</el-radio-button
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item label="参与方式:" prop="">
              <el-radio-group v-model="queryInfo.participationMode" @change="search">
                <el-radio-button
                  :label="item.id"
                  v-for="(item, index) in participationMode"
                  :key="index"
                  >{{ item.value }}</el-radio-button
                >
              </el-radio-group>
            </el-form-item>
            <el-form-item label="活动日期:">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="ruleForm.date"
                @change="checkParamsData"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="所在地区:" prop="">
              <city-select
                ref="child"
                :province="queryInfo.activityProvince"
                :city="queryInfo.activityCity"
                :district="queryInfo.activityDistrict"
                @searchProvince="searchProvince"
                @searchCity="searchCity"
                @searchDistrict="searchDistrict"
              />
            </el-form-item>

            <el-form-item
              label=" "
              label-width="0px"
              style="border-top: 1px dashed #efefef; padding-top: 20px; margin-bottom: 0"
            >
              <div class="search_row">
                <div class="search_row_left">
                  <el-button type="primary" @click="toPage('activityEditActivity', {})"
                    >新增活动</el-button
                  >
                  <el-button @click="updateOnShelf">批量发布</el-button>
                  <el-button @click="updateOffShelf">批量下架</el-button>
                  <div class="checkbox">
                    <el-checkbox
                      v-model="checkedAll"
                      @change="handleCheckAllChange"
                      :indeterminate="isIndeterminate"
                      >全选</el-checkbox
                    >
                  </div>
                </div>
                <div class="search_row_right">
                  <el-input placeholder="封面标题、案例主题" v-model="queryInfo.conditions">
                    <el-button class="search" slot="append" @click="search">搜索</el-button>
                  </el-input>
                </div>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <div class="center_count">
      <div class="center_count_left">
        <span>当前条件下共查出</span>
        <span class="color1">{{ total }}</span>
        <span>篇活动</span>
      </div>
      <div class="row">
        <div class="sort_box row" @click="sortChange('viewNumber')">
          <div>按查看人数</div>
          <div class="sort_icon_box">
            <div class="triangle_top_box" :class="getClass(1, 'viewNumber')"></div>
            <div class="triangle_bottom_box" :class="getClass(2, 'viewNumber')"></div>
          </div>
        </div>
        <div class="sort_box row" @click="sortChange('shareNumber')">
          <div>按分享人数</div>
          <div class="sort_icon_box">
            <div class="triangle_top_box" :class="getClass(1, 'shareNumber')"></div>
            <div class="triangle_bottom_box" :class="getClass(2, 'shareNumber')"></div>
          </div>
        </div>
        <div class="sort_box row" @click="sortChange('applicantsNumber')">
          <div>按报名人数</div>
          <div class="sort_icon_box">
            <div class="triangle_top_box" :class="getClass(1, 'applicantsNumber')"></div>
            <div class="triangle_bottom_box" :class="getClass(2, 'applicantsNumber')"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab_txt">
      <div class="storeCenter_item" v-for="(item, index) in listData" :key="index">
        <div class="item_checkbox">
          <el-checkbox
            @change="latechange($event, item)"
            v-model="item.checked1"
            label=""
          ></el-checkbox>
        </div>
        <!-- 活动卡片 -->
        <div class="storeCenter_item_top">
          <!-- 卡片左边 -->
          <div class="storeCenter_item_top_left">
            <div class="banner_box">
              <img :src="item.surfaceDrawing" alt="" />
              <!-- v-if="item.activityImg" -->
              <!-- <img v-else src="../../assets/image/Group 3237.png" alt="" /> -->
            </div>
            <div class="storeCenter_item_top_left_flex">
              <div class="title_row">
                <div class="title">
                  {{ item.activityName }}
                </div>
                <div class="title_row_item">
                  <img
                    v-if="item.participationMode === '1'"
                    src="../../../../assets/image/on-line.png"
                    alt=""
                  />
                  <img v-else src="../../../../assets/image/off-line.png" alt="" />
                  <span class="mode_txt">{{
                    item.participationMode === '1' ? '线上' : '线下'
                  }}</span>
                </div>
                <div class="state_box" :class="getActivityState(item, 'class')">
                  <img
                    v-if="item.activityState === '4'"
                    :src="require('@/assets/image/activity-state01.png')"
                    alt=""
                  />
                  <span>{{ getActivityState(item, 'txt') }}</span>
                </div>
              </div>
              <!-- 活动主题 -->
              <div class="center_row">{{ item.caseSubject }}</div>
              <div class="address">
                <div>
                  <span>所在地区：</span>
                  <span v-if="item.activityProvince">
                    {{ item.activityProvince }}
                    <span>-</span>
                    <span>{{ item.activityCity ? item.activityCity : '暂无' }}</span>
                  </span>
                  <span v-else>暂无</span>
                </div>
                <div v-if="item.activityPhase === '1'">
                  <div v-if="item.activityStartTime">
                    <span>活动时间：</span>
                    <span> {{ item.activityStartTime }}</span>
                    <span> 至 </span>
                    <span>{{ item.activityEndTime }}</span>
                  </div>
                  <div v-else>
                    <span>活动时间：暂无</span>
                  </div>
                </div>
                <div v-else>
                  <span>活动时间：</span>
                  <span> {{ item.activityProjectTime ? item.activityProjectTime : '暂无' }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 卡片中间 -->
          <div class="storeCenter_item_top_center">
            <div class="count_box click_box" @click="toPage('accountManagement', item)">
              <div class="num">{{ item.applicantsNumber ? item.applicantsNumber : 0 }}</div>
              <div>报名</div>
            </div>
            <div class="count_box">
              <div class="num">{{ item.viewNumber ? item.viewNumber : 0 }}</div>
              <div>查看</div>
            </div>
            <div class="count_box">
              <div class="num">{{ item.shareNumber ? item.shareNumber : 0 }}</div>
              <div>分享</div>
            </div>
          </div>
          <!-- 卡片右边 -->
          <div class="storeCenter_item_top_right">
            <div class="po_imgqr" @click="getQRCode(item)">
              <img
                src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img/Group%204010.png"
                alt=""
              />
            </div>
            <div class="pore_qr" @mouseleave="item.qrvisible = !item.qrvisible">
              <el-popover
                placement="left"
                title=""
                width="139"
                height="200"
                trigger="manual"
                v-model="item.qrvisible"
              >
                <div class="qr_top">
                  <span>签到二维码</span>
                </div>
                <div class="popover_img">
                  <img :src="QRCode" alt="" />
                </div>
                <div
                  style="
                    padding: 5px 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <el-button plain size="mini" @click="imgClick(item, QRCode)">导出图片</el-button>
                </div>
              </el-popover>
            </div>
            <div class="operation_area">
              <div v-if="item.isShelf" class="btn_box off_shelf" @click="OffShelf(item)">下架</div>
              <div v-else class="btn_box on_shelf" @click="OnShelf(item)">发布</div>
              <el-button
                class="set_seat_btn"
                type="info"
                @click="toPage('arrangeSeats', item)"
                plain
                >配置座位
              </el-button>

              <span class="edit" @click="toPage('activityEditActivity', item)">编辑</span>
              <span class="frozen" @click="setFreeze(item)">
                {{ item.isFreeze ? '激活' : '冻结' }}
              </span>
            </div>
            <div class="details">操作人：{{ item.updateName }}</div>
            <div class="details">最近操作时间：{{ item.updateTime }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="new_page" v-if="listData.length">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import {
  basicQrcode,
  activitylist,
  updateFreeze,
  updateShelf,
  freezeActivity,
  cancelFreezeActivity,
  updateOffShelf,
  updateOnShelf
} from '@/api/activecenter'
import CitySelect from '@/components/CitySelect.vue'
const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  activityProvince: '', // 省
  activityCity: '', // 市
  activityDistrict: '', // 区
  sortDirection: 'desc', // 排序方向：desc；asc
  sortType: null, // 排序类型（viewNumber：查看人数；shareNumber：分享人数；applicantsNumber：报名人数）
  activityEndTime: '', // 活动结束时间
  activityStartTime: '', // 活动开始时间
  activityState: '', // 活动状态
  activityPhase: '', // 活动阶段
  participationMode: '', // 参与方式（1、线上；2、线下）
  conditions: '' // 模糊查询条件（活动名称、所在城市）
})
export default {
  name: 'Userment',
  components: {
    CitySelect
  },
  data() {
    return {
      sortActive: 0, // 0是不激活排序，1是升序，2是降序
      messageCount: 0, // 节流阀
      isIndeterminate: false, // 半选框
      checkedAll: false, // 全选框
      QRCode: '', //签到二维码
      qrvisible: true, //显示签到二维码
      InformationTypes: [
        {
          value: '全部',
          id: ''
        },
        {
          value: '未开始',
          id: '1'
        },
        {
          value: '进行中',
          id: '2'
        },
        {
          value: '已结束',
          id: '3'
        }
        // {
        //   value: '预热中',
        //   id: '4'
        // }
      ],
      participationMode: [
        {
          value: '全部',
          id: ''
        },
        {
          value: '线上',
          id: '1'
        },
        {
          value: '线下',
          id: '2'
        }
      ],
      activityPhase: [
        {
          value: '全部',
          id: ''
        },
        {
          value: '预热中',
          id: '1'
        },
        {
          value: '规划中',
          id: '2'
        }
      ],
      ruleForm: {
        date: null
      },
      rules: {
        date: [{ required: true, message: '请选择账号周期', trigger: 'blur' }]
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      },

      dialogVisible: false,
      accountDialogVisible: false,
      currentId: null,
      ids: [],
      idsList: [],
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: false,
      // 总条数
      total: 0,
      idgisen: []
    }
  },
  computed: {
    // 计算活动状态
    getActivityState() {
      return function (item, type) {
        const state = item.activityState
        if (type === 'txt') {
          if (item.activityPhase === '2') {
            return '规划中'
          } else {
            if (state === '1') {
              return '未开始'
            } else if (state === '2') {
              return '进行中'
            } else if (state === '3') {
              return '已结束'
            } else if (state === '4') {
              return '预热中'
            }
          }
        } else {
          if (item.activityPhase === '2') {
            return 'state_five'
          } else {
            if (state === '1') {
              return 'state_one'
            } else if (state === '2') {
              return 'state_two'
            } else if (state === '3') {
              return 'state_three'
            } else if (state === '4') {
              return 'state_four'
            }
          }
        }
      }
    },
    // 计算时间排序图标的颜色
    getClass() {
      return function (val, sortType) {
        if (this.queryInfo.sortType === sortType) {
          let str = ''
          console.log(str)
          if (this.sortActive === 1 && val === 1) {
            str = 'top_active'
          } else if (this.sortActive === 2 && val === 2) {
            str = 'bottom_active'
          }
          return str
        }
      }
    }
  },
  created() {
    this.search()
  },
  methods: {
    // 重置节流阀
    resetMessageCount() {
      this.messageCount++
      setTimeout(() => {
        this.messageCount = 0
      }, 1000)
    },
    //活动一览
    async search() {
      this.loading = true
      const { data: res } = await activitylist(this.queryInfo)
      if (res.resultCode === 200) {
        this.loading = false
        this.total = res.data.total
        this.listData = res.data.list
        this.listData.forEach((item, index) => {
          this.$set(item, 'checked1', false) // 多选框value
          this.$set(item, 'qrvisible', false) // 显示二位码
          if (index < 1) {
            if (item.coverInformationVo.length) {
              // 有封面信息
              item.activityName = item.coverInformationVo[0].activityName
              item.surfaceDrawing = item.coverInformationVo[0].surfaceDrawing
            }
          }
        })
        this.handleCheckAllChange(false)
        // this.listData.forEach((item) => {
        //   if (item.labelName != null) {
        //     item.labelName = item.labelName.split(',')
        //     item.labelName = item.labelName.slice(0, 3)
        //   }
        // })
      } else {
        this.loading = false
      }
    },
    // 排序
    sortChange(val) {
      if (val === this.queryInfo.sortType) {
        // 如果点击的排序字段与上一次相同
        // 每点击一次排序字段加1
        this.sortActive++
        if (this.sortActive > 2) {
          // 大于2时重置为0
          this.sortActive = 0
        }
      } else {
        // 如果不相同则直接赋值为1
        this.sortActive = 1
      }
      if (this.sortActive === 0) {
        this.queryInfo.sortType = null
        this.queryInfo.sortDirection = 'desc'
      } else {
        this.queryInfo.sortType = val
        if (this.sortActive === 1) {
          // 为1时是升序
          this.queryInfo.sortDirection = 'asc'
        } else {
          // 为2时是降序
          this.queryInfo.sortDirection = 'desc'
        }
      }

      this.search()
      // if (val === 'viewNumber') {
      // } else if (val === 'shareNumber') {
      // } else {
      // }
    },
    //返回省
    searchProvince(val) {
      this.queryInfo.activityProvince = val
      this.queryInfo.activityCity = ''
      this.queryInfo.activityDistrict = ''
      this.search()
    },
    //返回城市
    searchCity(val) {
      this.queryInfo.activityCity = val
      this.queryInfo.activityDistrict = ''
      this.search()
    },
    //返回区/县
    searchDistrict(val) {
      this.queryInfo.activityDistrict = val
      this.search()
    },
    //获取签到二维码
    async getQRCode(item) {
      this.loading = true
      item.qrvisible = true
      const query = {
        sceneStr: '/pages/caseDetail/caseDetail?id=' + item.id + '&show=' + true
      }
      // console.log(query)
      const res = await basicQrcode(query)
      if (res.data) {
        this.loading = false
        let blob = new Blob([res.data])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        // console.log(href)
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        a.href = href // 指定下载'链接
        this.QRCode = href
      } else {
        this.$message.error('请求失败!')
      }
    },
    //下载二维码
    imgClick(item, href) {
      const a = document.createElement('a') //创建a标签
      a.style.display = 'none'
      a.href = href // 指定下载'链接
      this.QRCode = href
      item.qrvisible = true
      a.download = `${item.activityName}.png` //指定下载文件名
      // a.download = "test.png";
      a.click() //触发下载
      window.URL.revokeObjectURL(a.href) //释放URL对象
      if (document.body.contains(a)) {
        document.body.removeChild(a) //释放标签
      }
    },
    // 切换搜索列表的参数
    // checkParams(key, val) {
    //   this.queryInfo[key] = val
    //   this.search()
    // },
    // 选中日期
    checkParamsData(val) {
      if (val) {
        this.queryInfo.activityStartTime = val[0]
        this.queryInfo.activityEndTime = val[1]
      } else {
        this.queryInfo.activityStartTime = ''
        this.queryInfo.activityEndTime = ''
      }

      this.search()
    },
    //全选框
    handleCheckAllChange(bol) {
      //bol为布尔值，true是选中，false是取消
      if (bol) {
        this.listData.forEach((item) => {
          this.ids.push(item.id)
          this.idsList.push(item)
          this.$set(item, 'checked1', true)
        })
      } else {
        this.checkedAll = false
        this.listData.forEach((item) => {
          this.ids = []
          this.idsList = []
          this.$set(item, 'checked1', false)
        })
      }
      this.isIndeterminate = false
    },
    // 选中活动
    latechange(bol, row) {
      const index = this.ids.indexOf(row.id)
      if (index == -1) {
        this.ids.push(row.id)
        this.idsList.push(row)
      } else {
        this.ids.splice(index, 1)
        this.idsList.splice(index, 1)
      }
      if (bol) {
        if (this.ids.length == this.listData.length) {
          this.isIndeterminate = false
          this.checkedAll = true
          // console.log(this.checkedAll)
        } else {
          this.isIndeterminate = true
          this.checkedAll = false
        }
      } else {
        if (this.ids.length < 1) {
          this.isIndeterminate = false
          this.checkedAll = false
        } else {
          this.isIndeterminate = true
          this.checkedAll = false
        }
      }
    },
    // 设置上下架
    setShelf(ids, isShelf) {
      const param = {
        id: ids,
        isShelf
      }
      const txt = isShelf ? '上架' : '下架'
      this.$alert('是否确认' + txt + '活动？')
        .then(async () => {
          const { data: res } = await updateShelf(param)
          if (res.resultCode === 200) {
            this.$message.success(txt + '成功')
            this.search()
          }
        })
        .catch(() => {})
    },
    //单个下架
    OffShelf(row) {
      if (row.isFreeze) {
        // 冻结状态的活动
        if (this.messageCount === 0) {
          this.resetMessageCount()
          this.$message.warning('活动已冻结~')
        }
      } else {
        // 未冻结的活动
        const ids = [row.id]
        this.setShelf(ids, false)
      }
    },
    //单个上架
    OnShelf(row) {
      if (row.isFreeze) {
        // 冻结状态的活动
        if (this.messageCount === 0) {
          this.resetMessageCount()
          this.$message.warning('活动已冻结~')
        }
      } else {
        // 未冻结的活动
        const ids = [row.id]
        this.setShelf(ids, true)
      }
    },
    //批量下架
    updateOffShelf() {
      let bol = false
      this.idsList.forEach((el) => {
        if (el.isFreeze) {
          bol = true
        }
      })
      if (bol) {
        // 选中的活动中存在已冻结的活动
        this.$message.warning('选中的活动中存在已冻结的活动~')
      } else {
        // 选中的活动中没有已冻结的活动
        const ids = this.ids
        if (ids.length > 0) {
          this.setShelf(ids, false)
        } else {
          this.$message.warning('请选择活动')
        }
      }
    },
    //批量上架
    updateOnShelf() {
      let bol = false
      this.idsList.forEach((el) => {
        if (el.isFreeze) {
          bol = true
        }
      })
      if (bol) {
        // 选中的活动中存在已冻结的活动
        this.$message.warning('选中的活动中存在已冻结的活动~')
      } else {
        // 选中的活动中没有已冻结的活动
        const ids = this.ids
        if (ids.length > 0) {
          this.setShelf(ids, true)
        } else {
          this.$message.warning('请选择活动')
        }
      }
    },
    //编辑报名
    toPage(type, row) {
      if (row.isFreeze) {
        // 冻结状态的活动
        if (this.messageCount === 0) {
          this.resetMessageCount()
          this.$message.warning('活动已冻结~')
        }
      } else {
        // 未冻结的活动
        if (type === 'activityEditActivity') {
          if (row.isShelf) {
            // 已上架的活动不能编辑
            if (this.messageCount === 0) {
              this.resetMessageCount()
              this.$message.warning(
                '活动已上架，如已有用户报名编辑报名表单信息，此编辑会影响其他数据'
              )
            }
          }
          this.$router.push({
            path: '/activity/editactivity',
            query: {
              id: row.id,
              headTabActive: 0
            }
          })
        } else if (type === 'accountManagement') {
          if (row.activityPhase !== '2') {
            this.$router.push({
              path: '/activity/accountmanagement',
              query: {
                id: row.id
              }
            })
          } else {
            if (this.messageCount === 0) {
              this.resetMessageCount()
              this.$message.warning('此活动正在规划中，暂无报名人员信息~')
            }
          }
        } else if (type === 'arrangeSeats') {
          this.$router.push({
            path: '/activity/arrangeseats',
            query: {
              id: row.id
            }
          })
        }
      }
    },
    //标签
    clciklabel(row) {
      this.$router.push({
        path: '/activity/editActivity',
        query: {
          id: row.id,
          headTabActive: 2
        }
      })
    },
    // 解冻、冻结活动
    setFreeze(item) {
      const txt = item.isFreeze ? '激活' : '冻结'
      const isFreeze = !item.isFreeze
      const query = {
        id: item.id,
        isFreeze
      }
      this.$confirm('您是否确认' + txt + '？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const { data: res } = await updateFreeze(query)
          if (res.resultCode === 200) {
            this.$message.success(txt + '成功!')
            this.search()
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(() => {})
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.search_row {
  width: 100%;
  display: flex;
  padding: 0px 10px;
  box-sizing: border-box;
  .search_row_left {
    display: flex;
    .checkbox {
      line-height: 20px;
      margin-left: 15px;
      display: flex;
      align-items: flex-end;
    }
  }

  .search_row_right {
    margin-left: auto;
    width: 400px;
  }

  .search {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}
::v-deep .el-checkbox__inner::after {
  box-sizing: content-box;
  content: '';
  border: 1px solid #fff;
  border-left: 0;
  border-top: 0;
  height: 7px;
  left: 5px;
  position: absolute;
  top: 2px;
  transform: rotate(45deg) scaleY(0);
  width: 4px;
  transition: transform 0.15s ease-in 50ms;
  transform-origin: center;
}
::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 2px;
  box-sizing: border-box;
  width: 18px;
  height: 18px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}
.comble_txt {
  span {
    margin-left: 20px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
  }
}
.active_center {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
  .new_page {
    width: calc(100% - 200px);
    position: fixed;
    bottom: 16px;
    left: 200px;
    // z-index: 999;
    padding: 10px 0;
    margin-top: 20px;
    text-align: center;
    background-color: #fff;
    border-top: #f4f4f4 1px solid;
  }
  .form_info {
    width: 100%;
    background: #ffffff;
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
  }
  .center_count {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    line-height: 40px;
    color: #595959;
    padding: 10px 20px;
    box-sizing: border-box;
    .center_count_left {
      margin-right: 30px;
    }
    .sort_box {
      cursor: pointer;
      width: 100px;
      height: 40px;
      display: flex;
      align-items: center;
      margin-right: 10px;
      .sort_icon_box {
        display: flex;
        flex-flow: column;
        margin-left: 4px;
      }
    }
  }
  .tab_txt {
    width: 100%;
    // height: calc(100% - 560px);
  }

  .storeCenter_item {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    .item_checkbox {
      line-height: 36px;
      margin: 0px 10px;
    }
    .storeCenter_item_top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 16px 20px;
      box-sizing: border-box;
      position: relative;
      background-color: #ffffff;
      border-radius: 8px;
      border-top-right-radius: 0;
      .storeCenter_item_top_left {
        display: flex;
        align-items: center;
        width: 50%;
        .banner_box {
          width: 76px;
          height: 76px;
          margin-right: 16px;
          img {
            width: 76px;
            height: 76px;
          }
        }

        .storeCenter_item_top_left_flex {
          flex: 1;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          .title_row {
            display: flex;
            align-items: center;
            line-height: 24px;
            .title {
              font-family: 'PingFang SC';
              font-size: 18px;
              color: #151515;
              margin-right: 18px;
            }
            .title_row_item {
              display: flex;
              align-items: center;
              margin-right: 18px;
              img {
                width: 14px;
                height: 14px;
                margin-right: 8px;
              }
              .mode_txt {
                font-size: 14px;
                color: #6e6e6e;
              }
            }
            .state_box {
              display: flex;
              justify-content: center;
              min-width: 50px;
              padding: 0 10px;
              height: 18px;
              font-size: 14px;
              line-height: 18px;
              border-radius: 18px;
              // background-color: #999999;
              text-align: center;
              overflow: hidden;
              img {
                width: 18px;
                height: 18px;
              }
            }
            .state_one {
              background-color: #f3efeb;
              color: #c2946b;
            }
            .state_two {
              background-color: #fff3e9;
              color: #ff8a21;
            }
            .state_three {
              background-color: #f5f5f5;
              color: #9b9a9a;
            }
            .state_four {
              background-color: #fff3e9;
              color: #ff8a21;
            }
            .state_five {
              background-color: #f9f4f0;
              color: #c2946b;
            }
          }
          .center_row {
            margin: 10px 0;
          }
          .address {
            display: flex;
            font-size: 14px;
            div {
              padding-right: 20px;
            }
          }
        }
      }
      .storeCenter_item_top_center {
        display: flex;
        .count_box {
          height: 80px;
          width: 80px;
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          font-family: 'PingFang SC';
          font-size: 16px;
          color: #333333;
          .num {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 16px;
          }
        }
        .click_box {
          cursor: pointer;
        }
        .click_box:hover {
          color: #146aff;
        }
      }
      .storeCenter_item_top_right {
        margin-left: auto;
        margin-right: 30px;
        display: flex;
        flex-flow: column;
        align-items: flex-end;
        justify-content: center;
        height: 100%;
        .po_imgqr {
          position: absolute;
          right: 0px;
          top: 0px;
          cursor: pointer;
          img {
            width: 50px;
            height: 50px;
          }
        }
        .operation_area {
          display: flex;
          margin-top: 30px;
          line-height: 40px;
          .set_seat_btn {
            min-width: 100px;
            height: 40px !important;
          }
          ::v-deep .el-button {
            color: #333;
            background-color: #ededed;
            border-color: #cfcfcf;
          }
          ::v-deep .el-button:hover {
            opacity: 0.8;
          }
          .btn_box {
            cursor: pointer;
            width: 70px;
            height: 40px;
            text-align: center;
            color: #fff;
            border-radius: 4px;
            margin-right: 10px;
          }
          .btn_box:hover {
            opacity: 0.8;
          }
          .off_shelf {
            background-color: #ff9900;
          }
          .on_shelf {
            background-color: #448aff;
          }
        }
        .details {
          text-align: right;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          color: #999999;
          margin-top: 5px;
        }
      }
      .pore_qr {
        position: relative;
        left: -180px;
        top: 0;
        z-index: 999999 !important;

        .popover_img {
          display: flex;
          justify-content: center;
          img {
            width: 80px;
            height: 80px;
          }
        }
        .qr_top {
          display: flex;
          justify-content: center;
          color: #4e93fb;
        }
      }
    }
  }
  .edit {
    padding-left: 20px;
    color: #4e93fb;
    cursor: pointer;
  }
  .frozen {
    padding-left: 20px;
    color: #fd5469;
    cursor: pointer;
  }
  /deep/ .el-radio-button__inner {
    border: none;
    margin-right: 12px;
    border-radius: 4px;
  }
}
</style>

